<template>
  <div class="elv-entity-settings-container">
    <div class="elv-entity-settings-header">
      <div class="elv-entity-settings-header__title">
        {{ t('common.entitySettings') }}
      </div>
      <SvgIcon name="common-close" width="24" height="24" @click="onCloseSettings" />
    </div>
    <div class="elv-entity-settings-content">
      <ul class="elv-entity-settings-content-left">
        <li
          v-for="(menu, index) in menuList"
          :key="index"
          :class="{ 'is-active': menu.name === currentMenu }"
          @click="onCheckMenu(menu.name)"
        >
          {{ menu.label }}
        </li>
      </ul>
      <div class="elv-entity-settings-content-right">
        <template v-if="currentMenu === 'general'">
          <el-scrollbar class="elv-entity-settings-general-content">
            <ProjectSettingForm model="edit" :current-entity-data="currentEntityData" from-type="entity" />
          </el-scrollbar>
        </template>

        <Integrations v-if="currentMenu === 'integrations'" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import Integrations from './components/Integrations.vue'
import { useReportStore } from '@/stores/modules/reports/index'
import ProjectSettingForm from '@/components/Project/ProjectSettingForm.vue'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const reportStore = useReportStore()

const currentMenu = ref('general')

const menuList = [
  {
    name: 'general',
    label: t('common.general')
  },
  {
    name: 'integrations',
    label: t('common.integrations')
  }
]

const entityId = computed(() => {
  return Number(route.params?.entityId)
})

const projectId = computed(() => {
  return Number(route.params?.projectId)
})

const currentEntityData = computed(() => {
  return reportStore.entityDetail
})

const onCheckMenu = (menu: string) => {
  if (currentMenu.value === menu) return
  currentMenu.value = menu
}

const onCloseSettings = () => {
  router.push({ name: 'project-settings', params: { projectId: projectId.value } })
}

onMounted(async () => {
  await reportStore.fetchEntityDetail(entityId.value)
  if (route.query?.type === 'Integrations') {
    currentMenu.value = 'integrations'
  }
})
</script>

<style lang="scss" scoped>
.elv-entity-settings-container {
  width: 100%;
  position: relative;

  .elv-entity-settings-header {
    width: 900px;
    margin: 20px auto;

    .elv-entity-settings-header__title {
      padding-bottom: 19px;
      margin: 0 auto 24px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      color: #0e0f11;
      border-bottom: 1px solid #dde1e6;
    }

    svg {
      fill: #1e2024;
      position: absolute;
      // right: -108px;
      right: 40px;
      top: 4px;
      cursor: pointer;
    }
  }

  .elv-entity-settings-content {
    width: 900px;
    height: calc(100vh - 85px);
    margin: 0 auto;
    display: flex;
  }

  .elv-entity-settings-content-right {
    .elv-entity-settings-general-content {
      :deep(.el-scrollbar__view) {
        padding-right: 20px;

        .elv-apply-project-form {
          margin-top: 0px;
        }
      }
    }
  }

  .elv-entity-settings-content-left {
    width: 87px;
    margin-right: 113px;

    li {
      height: 18px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #636b75;
      margin-bottom: 24px;
      cursor: pointer;

      &.is-active {
        color: #0e0f11;
        font-weight: 700;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .elv-entity-settings-general-content__title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #0e0f11;
    padding-bottom: 12px;
  }
}
</style>
