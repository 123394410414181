<template>
  <div class="elv-entity-setting-integrations">
    <div v-if="false" class="elv-entity-setting-integrations__quickbooks">
      <div>
        <img src="@/assets/img/reports/Logo-Quickbooks.png" alt="Quickbooks" />
        <div class="elv-entity-setting-integrations-operating">
          <elv-button v-if="true" height="32" plain
            ><SvgIcon name="link" width="16" height="16" />{{ t('button.connect') }}</elv-button
          >
          <template v-else>
            <elv-button height="32" plain
              ><SvgIcon name="unlink" width="16" height="16" />{{ t('button.disconnect') }}</elv-button
            >
            <elv-button height="32">{{ t('button.accountMappings') }}</elv-button>
          </template>
        </div>
      </div>
      <p class="elv-entity-setting-integrations-info">
        {{ t('title.connectIntegrationInfo', { platform: 'Quickbooks' }) }}
      </p>
    </div>
    <div class="elv-entity-setting-integrations__xero">
      <div>
        <img src="@/assets/img/reports/Logo-Xero.png" alt="Xero" />
        <div class="elv-entity-setting-integrations-operating">
          <elv-button v-if="!reportStore.entityDetail?.hasIntegration" height="32" plain @click="onConnectERP"
            ><SvgIcon name="link" width="16" height="16" />{{ t('button.connect') }}</elv-button
          >
          <template v-else>
            <elv-button height="32" plain @click="onDisconnectIntegration"
              ><SvgIcon name="unlink" width="16" height="16" />{{ t('button.disconnect') }}</elv-button
            >
            <elv-button height="32" :loading="accountMappingLoading" @click="onEditAccountMappings">{{
              t('button.accountMappings')
            }}</elv-button>
          </template>
        </div>
      </div>
      <p class="elv-entity-setting-integrations-info">
        {{ t('title.connectIntegrationInfo', { platform: 'Xero' }) }}
      </p>
    </div>
  </div>
  <AccountMappingsDialog ref="accountMappingsRef" />

  <MessageBox
    ref="disconnectPopConfirmRef"
    :confirm-button-text="t('button.disconnect')"
    :cancel-button-text="t('button.cancel')"
    :title="t('title.disconnectWithPlatform', { platfrom: 'Xero' })"
    :loading="disconnectLoading"
    @onConfirmEvent="onConfirmDisconnect"
    @onCancelEvent="onCloseConfirm"
  >
    <template #content>
      <span class="elv-confirm-disconnect-info__title">{{
        t('message.disconnectWithPlatformInfo', { platfrom: 'Xero' })
      }}</span></template
    >
  </MessageBox>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import ReportsApi from '@/api/ReportsApi'
import MessageBox from '@/components/MessageBox.vue'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import { useReportStore } from '@/stores/modules/reports/index'
import AccountMappingsDialog from '@/components/Reports/AccountMappingsDialog.vue'

const { t } = useI18n()
const route = useRoute()

const reportStore = useReportStore()
const userGlobalStore = useUserGlobalStore()

const disconnectPopConfirmRef = ref()
const accountMappingsRef = ref()
const disconnectLoading = ref(false)
const accountMappingLoading = ref(false)

const entityId = computed(() => {
  return Number(route.params.entityId)
})

const projectId = computed(() => {
  return Number(route.params.projectId)
})

const onConnectERP = () => {
  const redirectUrl = encodeURIComponent(`${import.meta.env.VITE_XERO_REDIRECT_URL}`)
  const scope = encodeURIComponent(
    'offline_access openid profile email accounting.transactions accounting.settings accounting.attachments'
  )
  const clientId = import.meta.env.VITE_XERO_CLIENT_ID

  const url = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&scope=${scope}&state=${userGlobalStore.user?.userId}|${entityId.value}|${projectId.value}`
  window.open(url, '_self')
}

const onDisconnectIntegration = async () => {
  disconnectPopConfirmRef.value?.onCheckMessageBoxDialog()
}

const onCloseConfirm = () => {
  disconnectPopConfirmRef.value?.onCheckMessageBoxDialog()
}

const onConfirmDisconnect = async () => {
  try {
    disconnectLoading.value = true
    await ReportsApi.cancelEntityAuthErp(entityId.value)
    ElMessage.success(t('message.successfullyDisconnected', { platform: 'Xero' }))
    reportStore.fetchEntityDetail(entityId.value)
    disconnectPopConfirmRef.value?.onCheckMessageBoxDialog()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error?.message)
  } finally {
    disconnectLoading.value = false
  }
}

const onEditAccountMappings = async () => {
  try {
    accountMappingLoading.value = true
    await reportStore.fetchAccountMappingList(entityId.value)
    accountMappingsRef.value?.onCheckAccountMappingsDialog()
  } catch (error) {
    console.log(error)
  } finally {
    accountMappingLoading.value = false
  }
}
</script>

<style lang="scss" scoped>
.elv-entity-setting-integrations {
  width: 572px;
}

.elv-entity-setting-integrations__quickbooks,
.elv-entity-setting-integrations__xero {
  width: 100%;
  display: flex;
  padding: 20px;
  box-sizing: border-box;
  flex-direction: column;
  background-color: #f9fafb;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      display: block;
    }

    .elv-entity-setting-integrations-operating {
      display: flex;

      :deep(.elv-button.is-plain) {
        color: #1e2024;
        border-color: #dde1e6;

        svg {
          fill: #1e2024;
        }

        &.is-disabled {
          color: #d0d4d9 !important;
          background-color: #f9fafb !important;
          box-shadow: none !important;

          svg {
            fill: #d0d4d9 !important;
          }
        }

        &:not(.is-disabled):focus,
        &:not(.is-disabled):hover {
          border: 1px solid #5e85eb;
          color: #1343bf;

          svg {
            fill: #1343bf;
          }
        }
      }
    }
  }
}

.elv-entity-setting-integrations__quickbooks {
  height: 118px;
  margin-bottom: 16px;

  img {
    width: 118px;
    height: 32px;
  }
}

.elv-entity-setting-integrations__xero {
  height: 100px;

  img {
    width: 32px;
    height: 32px;
  }
}

.elv-confirm-disconnect-info__title {
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: start;
  color: #1e2024;
  word-break: break-word;
}

.elv-entity-setting-integrations-info {
  color: #636b75;
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 10px;
}
</style>
